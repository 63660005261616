import { useRef, useEffect } from "react";
import { Link } from "@reach/router";
import useMouse from "@react-hook/mouse-position";
import styles from "./cardlink.module.scss";


function presetContents(content) {
    const { title, sub, body } = content;
    return (
      <>
        <div className={styles.title}>{title}</div>
        <div className={styles.sub}>{sub}</div>
        <p className={styles.body}>{body}</p>
      </>
    );
  };

function CardLink({ children, to, contents = false }) {
  const ref = useRef(null);
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  useEffect(() => {
    function angle(partial, total) {
      if (!partial || !total) {
        return 0;
      }
      const percent = (100 * partial) / total;
      const tenToMinusTen = percent / 5 - 10;
      const invert = tenToMinusTen - tenToMinusTen * 2;
      return Math.floor(invert);
    }
    let xPos = angle(mouse.x, Math.floor(mouse.elementWidth));
    let yPos = angle(mouse.y, Math.floor(mouse.elementHeight));

    ref.current.style.setProperty("--x", `${mouse.x}px`);
    ref.current.style.setProperty("--y", `${mouse.y}px`);
    ref.current.style.setProperty("--shadowX", `${xPos}px`);
    ref.current.style.setProperty("--shadowY", `${yPos}px`);
  });



  return (
    <Link className={styles.card} to={to} ref={ref}>
      {contents ? presetContents(contents) : children}
    </Link>
  );
}

function CardButton({ children, handleClick }) {
  const ref = useRef(null);
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  useEffect(() => {
    function angle(partial, total) {
      if (!partial || !total) {
        return 0;
      }
      const percent = (100 * partial) / total;
      const tenToMinusTen = percent / 5 - 10;
      const invert = tenToMinusTen - tenToMinusTen * 2;
      return Math.floor(invert);
    }
    let xPos = angle(mouse.x, Math.floor(mouse.elementWidth));
    let yPos = angle(mouse.y, Math.floor(mouse.elementHeight));

    ref.current.style.setProperty("--x", `${mouse.x}px`);
    ref.current.style.setProperty("--y", `${mouse.y}px`);
    ref.current.style.setProperty("--shadowX", `${xPos}px`);
    ref.current.style.setProperty("--shadowY", `${yPos}px`);
  });



  return (
    <button className={styles.cardButton} onClick={handleClick} ref={ref}>
      {children}
    </button>
  );
}

function DummyCardLink({contents}){
    return(
        <div className={styles.dummyCard}>
            {presetContents(contents)}
        </div>
    )
}

export {CardLink, DummyCardLink, CardButton};
