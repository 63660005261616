import { createContext } from "react";
import useLocalStorageState from './hooks/use-local-storage';

export const themes = { dark: "dark", light: "light" };

export const ThemeContext = createContext({
  theme: themes.dark,
  toggleTheme: () => {},
});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorageState('theme',themes.dark);
  const toggleTheme = (mode) =>
    setTheme(mode === "dark" ? themes.dark : themes.light);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
