import useFetch from "./hooks/use-fetch";
import MarkdownContent from './MarkdownContent';
import NotFound from "./NotFound";
import styles from "./work.module.scss";

function Work({ pagePath }) {

  const pageContent = useFetch(
    `${process.env.PUBLIC_URL}/work/${pagePath}.md`
  );

  const pageData = {
      title: 'SparkNotes',
        dates: '2019-2020'
    };
  if (!pageData) {
    return <NotFound />;
  }

  if (pageContent.loaded) {
    return (
      <>
      <header className={styles.header}>
        <h1 className={styles.title}>{pageData.title}</h1>
        <div className={styles.dates}
        >
          {pageData.dates}
        </div>
        </header>
        <MarkdownContent markdownData={pageContent.data} />
      </>
    );
  }
  return <div>loading</div>;
}

export default Work;
