import { useState, useEffect } from "react";

function useFetch(url) {
  const [data, setData] = useState({});

  useEffect(() => {
    fetch(url)
      .then((response) => {
        return response.text();
      })
      .then((result) => {
        setData({ loaded: true, data: result });
      });
  }, [url]);

  return data;
}

export default useFetch;
