import useFetch from "./hooks/use-fetch";
import MarkdownContent from './MarkdownContent';
import NotFound from "./NotFound";
import { getDate } from "./helpers";
import articlesData from "./data/articles";
import styles from "./article.module.scss";



function Article({ pagePath }) {
  const pageContent = useFetch(
    `${process.env.PUBLIC_URL}/articles/${pagePath}.md`
  );

  const pageData = articlesData[pagePath];
  if (!pageData) {
    return <NotFound />;
  }

  const publishedDate = getDate(pageData.publishedDate);

  if (pageContent.loaded) {
    return (
      <>
      <header className={styles.header}>
        <h1 className={styles.title}>{pageData.title}</h1>
        <time
          className={styles.date}
          title={`Originally published ${publishedDate}`}
          dateTime=""
        >
          {publishedDate}
        </time>
        </header>
        <MarkdownContent markdownData={pageContent.data} />
      </>
    );
  }
  return <div>loading</div>;
}

export default Article;
