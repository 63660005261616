import styles from "./me.module.scss";

function Me() {
  return (
    <div className={styles.me}>
      <section className={styles.section}>
        <h2 className={styles.section_heading}>Chris Boon - he/him</h2>
        <p>
          I'm a UX designer and developer with a passion for improving lives
          through technology.
        </p>

        <p>
          I'm currently working as a developer at{" "}
          <a className={styles.inlineLink} href="https://jefferies.com/">
            Jefferies
          </a>
          , and previously worked as a UX designer and developer at{" "}
          <a className={styles.inlineLink} href="https://www.sparknotes.com/">
            SparkNotes
          </a>{" "}
          and{" "}
          <a className={styles.inlineLink} href="https://global.oup.com/?cc=us">
            Oxford University Press
          </a>
          .
        </p>

        <p>
          I have a focus on user-centered design, usability, and high quality
          prototyping. I’m passionate about good design, accessibility for
          everybody, and making products that enrich people's lives. I believe
          code should be easy to read and understand, highly
          maintainable/adaptable, and blazing fast. I’m a strong believer that
          results can and should be measurable, and that good design and
          development result in happier customers and quantifiable business
          benefits.
        </p>

        <p>
          Outside of work you can find me... stuck in the apartment because
          covid 🤷‍♀️. But before all this I was spending my free time doing some
          beginner level woodwork, keeping fit in the gym, hanging out in Jersey
          City, and taking my daughter to coffee shops, cafes, diners,
          restaurants, and food trucks.
        </p>
      </section>
    </div>
  );
}

export default Me;
