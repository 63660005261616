import { Router } from "@reach/router";
import OnRouteChange from "./thirdparty/reach-router-scroll-top";

import { useEffect, useContext } from "react";
import { ThemeContext } from "./theme-context";
import Header from "./Header";
import Home from "./Home";
import Me from "./Me";
import Article from "./Article";
import Work from "./Work";
import NotFound from "./NotFound";
import styles from "./App.module.scss";

function App() {
  // handle class name change on html element.
  // all color changes happen due to changing of css custom properties when this class changes.
  // see index.css
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    const doc = document.documentElement;
    theme === "dark"
      ? doc.classList.remove("theme--light")
      : doc.classList.add("theme--light");
  }, [theme]);

  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.main}>
        <Router primary={false}>
          <NotFound default />
          <Home path="/" />
          <Me path="/me" />
          <Work path="/work/:pagePath"/>
          <Article path="/articles/:pagePath" />
        </Router>
      </div>
        <OnRouteChange />
      <footer className={styles.footer}>
        <div className={styles.footerContainer}>
          <p className={styles.footerName}>Chris Boon, UX designer and developer.</p>
          <p>
            © Obligatory copyright notice: The stuff on this site is all mine -
            except when it's not. Also I learned most of it from other people,
            so... 🤷. But seriously I hope you find this content useful. Do what
            you want with it as long as you're not just straight up ripping it
            off. Also if there's anything on here you think I didn't give
            sufficient credit to others, let me know and I'll try and resolve
            asap.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
