import React, { useEffect, useRef } from "react";
import { Location } from "@reach/router";
const defaultAction = () => {
	window.scrollTo(0, 0);
};
export const OnRouteChangeWorker = props => {
	const locationRef = useRef("");
	useEffect(() => {
		if (props.location.pathname !== locationRef.current) {
			locationRef.current = props.location.pathname;
			props.action();
		}
	});
	return null;
};
const OnRouteChange = ({ action = defaultAction }) => (
	<Location>
		{({ location }) => (
			<OnRouteChangeWorker location={location} action={action} />
		)}
	</Location>
);
export default OnRouteChange;

//https://github.com/DarkSmile92/reach-router-scroll-top