import { useEffect, useState, useContext } from "react";
import { Link } from "@reach/router";
import { useInView } from "react-intersection-observer";
import { ThemeContext } from "./theme-context";
import Boop from "./Boop";
import styles from "./header.module.scss";

function getRandomEmoji() {
  const emoji = [
    "😃",
    "😁",
    "😆",
    "🤣",
    "🙃",
    "😉",
    "😋",
    "😝",
    "🧐",
    "🥸",
    "🥳",
    "🤯",
    "😷",
    "🥶",
    "💂‍♀️",
    "👨‍🎤",
    "🧑‍🍳",
    "🧑‍💻",
    "🔥",
    "🐣",
    "🎈",
    "🧸",
    "☃️",
    "🍩",
    "🦥",
  ];
  const count = emoji.length;
  const random = Math.floor(Math.random() * count);
  return emoji[random];
}

function getFaviconEl() {
  return document.querySelector("#favicon");
}

const faviconString = (favicon) =>
  `data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%2285%22 font-size=%2290%22>${favicon}</text></svg>`;

function Logo({ minimal }) {
  const [emoji, setEmoji] = useState(getRandomEmoji());
  const [timer, setTimer] = useState(5000);

  useEffect(() => {
    const favicon = getFaviconEl();
    const updateEmoji = () => {
      const newEmoji = getRandomEmoji();
      setEmoji(newEmoji);
      favicon.href = faviconString(newEmoji);
    };

    updateEmoji();
    const interval = setInterval(() => updateEmoji(), timer);
    return () => {
      clearInterval(interval);
    };
  }, [timer]);
  return (
    <div className={minimal ? styles.minimal : ""}>
      <Link to="/" className={styles.link}>
        <h1
          className={styles.logo}
          onMouseEnter={() => {
            setTimer(150);
          }}
          onMouseLeave={() => {
            setTimer(5000);
          }}
        >
          C<span className={styles.minimal_hide}>hris </span>B
          <span role="img" aria-label="o">
            {emoji}
          </span>
          <span className={styles.minimal_hide}>on</span>
        </h1>
      </Link>
    </div>
  );
}

function ColorModeToggle() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const handleChange = (mode) => {
    toggleTheme(mode);
  };
  return (
    <fieldset className={styles.toggle}>
      <legend className={styles.toggle_legend}>Choose a color scheme:</legend>
      <input
        className={styles.toggle_radio}
        type="radio"
        name="colorMode"
        id="dark"
        value="dark"
        checked={theme === "dark"}
        onChange={() => {
          handleChange("dark");
        }}
      />
      <label
        htmlFor="dark"
        className={`${styles["toggle_label"]} ${styles["toggle_label--dark"]}`}
      >
        <Boop
          rotation={-5}
          scale={1.2}
          timing={200}
          disabled={theme === "dark"}
        >
          <span role="img" aria-label="Dark mode">
            🌛
          </span>
        </Boop>
      </label>
      <input
        className={styles.toggle_radio}
        type="radio"
        name="colorMode"
        id="light"
        value="light"
        checked={theme === "light"}
        onChange={() => {
          handleChange("light");
        }}
      />
      <label
        htmlFor="light"
        className={`${styles["toggle_label"]} ${styles["toggle_label--light"]}`}
      >
        <Boop
          rotation={5}
          scale={1.2}
          timing={200}
          disabled={theme === "light"}
        >
          <span role="img" aria-label="Light mode">
            🌞
          </span>
        </Boop>
      </label>
    </fieldset>
  );
}

function Header() {
  const { ref, inView } = useInView({ initialInView: true });

  return (
    <header
      className={`${styles.header} ${inView ? "" : styles["header-minimal"]}`}
    >
      <div ref={ref} className={styles.sentinel}></div>
      <div className={styles.container}>
        <Logo minimal={!inView} />
        <ColorModeToggle />
      </div>
    </header>
  );
}

export default Header;
