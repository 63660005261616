import { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown/with-html";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  materialLight,
  materialDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

import { ThemeContext } from "./theme-context";

import { CardButton } from "./CardLink";

import styles from "./markdownContent.module.scss";

function slugify(text) {
  return text.toLowerCase().replaceAll(" ", "-");
}

function MediaRow({ data, showLightBoxContent }) {
  return (
    <div className={styles.mediaRow}>
      {data.content.map((img) => {
        return (
          <div
            className={styles.mediaRowItem}
            key={img.url}
            onClick={() => {
              showLightBoxContent(img.url);
            }}
          >
            <CardButton>
              {img.heading && (
                <div className={styles.mediaRowHeading}>{img.heading}</div>
              )}
              <img src={img.url} alt="" />
            </CardButton>
          </div>
        );
      })}
    </div>
  );
}

function CustomHeading(props) {
  const text = props.node.children[0].value;
  useEffect(() => {
    if (props.level === 2) {
      const line = props.node.position.start.line;
      props.addHeadingToJumpNav({ text, line });
    }
  });
  if (props.level === 2) {
    const slug = slugify(text);
    return (
      <h2 className={styles.linkTitle}>
        <a className={styles.linkTitleAnchor} id={slug} href={`#${slug}`}>
          {text}
        </a>
      </h2>
    );
  }
  if (props.level === 3) {
    return <h3 className={styles.h3}>{text}</h3>;
  }
  if (props.level === 4) {
    return <h4>{text}</h4>;
  }
  if (props.level === 5) {
    return <h5>{text}</h5>;
  }
  if (props.level === 6) {
    const data = JSON.parse(props.node.children[0].value);
    return (
      <MediaRow data={data} showLightBoxContent={props.showLightBoxContent} />
    );
  }
}

const renderers = (theme, addHeadingToJumpNav, showLightBoxContent) => {
  const renderStyles = theme === "dark" ? materialDark : materialLight;

  return {
    code: ({ language, value }) => {
      return (
        <div
          className={`${styles.codeBlock} ${styles["codeBlock-" + language]}`}
        >
          <SyntaxHighlighter
            styles={renderStyles}
            wrapLongLines={true}
            language={language}
            children={value}
          />
        </div>
      );
    },
    heading: (props) => {
      return (
        <CustomHeading
          addHeadingToJumpNav={addHeadingToJumpNav}
          showLightBoxContent={showLightBoxContent}
          {...props}
        />
      );
    },
    image: ({ src, title, alt }) => {
      return <img src={src} title={title} alt={alt} loading="lazy" />;
    },
  };
};

function MarkdownContent({ markdownData, showJumpNav = true }) {
  const { theme } = useContext(ThemeContext);

  const [headings, setHeadings] = useState([]);
  const addHeadingToJumpNav = (heading) => {
    if (!headings.some((item) => item.text === heading.text)) {
      setHeadings([...headings, heading].sort((a, b) => a.line - b.line));
    }
  };

  const [lightbox, setLightbox] = useState(null);
  const showLightBoxContent = (url) => {
    console.log("was clicked");
    setLightbox(url);
  };
  const closeLightbox = () => setLightbox(null);

  return (
    <>
      <div>
        <Dialog
          isOpen={Boolean(lightbox)}
          onDismiss={closeLightbox}
          aria-label="close up of the image you just clicked"
        >
          {lightbox && (
            <img
              className={styles.lightboxImage}
              src={lightbox.replace("/images/", "/images/2x-")}
              alt=""
            />
          )}
        </Dialog>
      </div>
      <div className={styles.articleText}>
        {showJumpNav && headings && (
          <div className={styles.jumpNav}>
            <div className={styles.jumpNavContainer}>
            <span className={styles.jumpNavLabel}>Jump to:</span>
            {headings.map(({ text }) => (
              <a
                key={text}
                href={`#${slugify(text)}`}
                className={styles.jumpNavLink}
              >
                {text}
              </a>
            ))}
          </div>
          </div>
        )}
        <div className={styles.content}>
          <ReactMarkdown
            renderers={renderers(
              theme,
              addHeadingToJumpNav,
              showLightBoxContent
            )}
            children={markdownData}
            allowDangerousHtml
          />
        </div>
      </div>
    </>
  );
}
export default MarkdownContent;
