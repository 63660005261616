import { Link } from "@reach/router";
import { CardLink, DummyCardLink } from "./CardLink";
import { getDate } from "./helpers";
import styles from "./home.module.scss";
import articlesData from "./data/articles";

function Articles() {
  const articlesArray = Object.entries(articlesData).map((article) => {
    return {
      url: article[0],
      ...article[1],
    };
  });
  return (
    <section className={styles.section}>
      <h2 className={styles.section_heading}>Articles</h2>
      <div className={styles.articles}>
        {articlesArray.map((article) => {
          const { url, publishedDate, title, description } = article;
          const date = getDate(publishedDate);
          return (
            <CardLink
              key={url}
              to={`/articles/${url}`}
              contents={{ title: title, sub: date, body: description }}
            ></CardLink>
          );
        })}
      </div>
    </section>
  );
}

function Work() {
  return (
    <section className={styles.section}>
      <h2 className={styles.section_heading}>Work</h2>
      <div className={styles.work}>
        <DummyCardLink
          contents={{
            title: "Jefferies",
            sub: "2020 - present",
            body:
              "I’m currently working on internal software, so can’t really share much about it - my lips are 🤐.",
          }}
        />
        <CardLink
          to={`/work/sparknotes`}
          contents={{
            title: "SparkNotes",
            sub: "2019 - 2020",
            body:
              "I worked at SparkNotes on the UI and UX and also took on a key role in the frontend development. Some of the things we implemented during my time there are documented here.",
          }}
        ></CardLink>
        <DummyCardLink
          contents={{
            title: "OUP",
            sub: "2007 - 2019",
            body:
              "I’m still pulling together stuff from this period. Check back soon.",
          }}
        />
      </div>
    </section>
  );
}

function Home() {
  return (
    <div className={styles.home}>
      <section className={styles.section}>
        <h2 className={styles.section_heading}>Hi.</h2>
        <p className={styles.largeText}>
          I'm{" "}
          <Link className={styles.inlineLink} to="/me">
            <span>Chris</span>
          </Link>{" "}
          – a UX designer and developer with a passion for improving lives
          through technology.
        </p>

        <p className={styles.largeText}>
          I'm currently working as a developer at{" "}
          <a className={styles.inlineLink} href="https://jefferies.com/">
            Jefferies
          </a>
          , and previously worked as a UX designer and developer at{" "}
          <a className={styles.inlineLink} href="https://www.sparknotes.com/">
            SparkNotes
          </a>{" "}
          and{" "}
          <a className={styles.inlineLink} href="https://global.oup.com/?cc=us">
            Oxford University Press
          </a>
          .
        </p>
      </section>

      <Work />
      <Articles />

      <section className={styles.section}>
        <h2 className={styles.section_heading}>Snippets</h2>
        <p>👷 This is coming soon too... I guess I released this site a little early 🤨 </p>
      </section>
    </div>
  );
}

export default Home;
