const articlesData = {
  "dynamic-favicons": {
    title: "Dynamic favicons",
    publishedDate: "2020-12-31",
    description: "A favicon link is like any other element on your page - you can update the html using javascript and see the change instantly. Here’s how you can do it and some ideas how it might be useful.",
    tags: []
  },
};

export default articlesData;
